.link {
  color: #000000 !important;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.5rem;
  padding: 10px 50px;
  display: block;
  border-bottom: 1px solid #e5e5e5;
}
