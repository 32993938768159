.headerWrapper {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  transition: background-color ease 0.3s;
  background-color: transparent;
}

.headerWrapper a {
  color: #ffffff;
}
