.headingNews {
  color: #f16d25 !important;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif !important;
}

.dateNews {
  color: #f16d25 !important;
  text-transform: uppercase;
}

.titleNews {
  font-weight: 600;
  color: #000000;
  font-size: 1.4rem !important;
}

.textNews {
  font-size: 0.9rem !important;
  color: #7f7f7f;
}
