.brand {
  color: #a0a0a0;
  font-weight: 600;
}

.heading {
  color: #f16d25;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;
  margin: 0.3rem 0;
}

.code {
  font-size: 0.7rem;
}

.description {
  font-size: 0.9rem;
}
