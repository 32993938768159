.productCountTitle {
  font-size: 1.65rem;
  font-family: Montserrat, sans-serif;
}
.productCountNumber {
  font-weight: 900;
  background-color: #000000;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
}
.productCountName {
  font-weight: 900;
  color: #f16d25;
  font-size: 2rem;
}

.productSidebarTitle {
  color: #f16d25;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.1rem;
}
