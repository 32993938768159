.titleKiero {
  text-transform: uppercase;
  color: #ffffff;

  font-family: 'Montserrat', sans-serif !important;
  font-weight: 900 !important;
}

.kieroBrand {
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 500;
}

.dividerTitles {
  font-family: 'Montserrat' !important;
}

.titleAbout {
  font-family: 'Montserrat' !important;
  font-weight: 600;
  position: relative;
  padding-left: 40px;
}

.titleAbout::before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 13px;
  left: 0px;
  background-color: #f16d25;
  border-radius: 8px;
}

.paragraphAbout {
  font-weight: 600;
  font-family: Montserrat;
}

.titleKieroCustom {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 900;
  position: relative;
  width: auto;
}

.titleKieroCustom::after {
  content: '';
  display: block;
  width: 110%;
  height: 30px;
  background-color: #f16d25;
  position: absolute;
  bottom: 15px;
  left: -4%;
  z-index: -1;
}
