.buttonKiero {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#518ea9",endColorstr="#143fe9",GradientType=1);
  border-radius: 15px;
  padding: 5px 20px;
  color: #ffffff !important;
  display: inline-block;
  margin-top: 10px;
}

.buttonKiero:hover {
  text-decoration: none !important;
}

.buttonKiero::after {
  content: '>';
  margin-left: 5px;
}

.templ1 {
  background: rgb(81, 142, 169);
  background: -moz-linear-gradient(
    90deg,
    rgba(81, 142, 169, 1) 0%,
    rgba(20, 63, 233, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(81, 142, 169, 1) 0%,
    rgba(20, 63, 233, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(81, 142, 169, 1) 0%,
    rgba(20, 63, 233, 1) 100%
  );
}

.templ2 {
  background: rgb(159, 18, 51);
  background: -moz-linear-gradient(
    90deg,
    rgba(159, 18, 51, 1) 0%,
    rgba(165, 120, 55, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(159, 18, 51, 1) 0%,
    rgba(165, 120, 55, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(159, 18, 51, 1) 0%,
    rgba(165, 120, 55, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9f1233",endColorstr="#a57837",GradientType=1);
}

.templ3 {
  background: rgb(70, 79, 57);
  background: -moz-linear-gradient(
    90deg,
    rgba(70, 79, 57, 1) 0%,
    rgba(36, 126, 59, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(70, 79, 57, 1) 0%,
    rgba(36, 126, 59, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(70, 79, 57, 1) 0%,
    rgba(36, 126, 59, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#464f39",endColorstr="#247e3b",GradientType=1);
}

.buttonLarge {
  background-color: #f16d25;
  color: #ffffff !important;
  padding: 10px 70px;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 10px;
}

.buttonLargeWhite {
  color: #f16d25 !important;
  background-color: #ffffff !important;
  padding: 10px 50px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 10px;
  flex: 0;
}

.buttonLargeWhiteUpper {
  color: #f16d25 !important;
  background-color: #ffffff !important;
  padding: 10px 60px;
  font-size: 1.2rem;
  font-weight: 800;
  border-radius: 20px;
  text-transform: uppercase;
}

.buttonLargeTwo {
  text-transform: uppercase;
  background-color: #f16d25;
  color: #ffffff !important;
  padding: 10px 50px;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 30px;
}

.buttonLarge:hover,
.buttonLargeWhite:hover {
  text-decoration: none !important;
}

.buttonBuy {
  color: #000000;
  text-transform: uppercase;
  border: 2px solid #f16d25;
  border-radius: 30px;
  padding: 5px;
  display: block;
  text-align: center;
  font-weight: 800;
  margin-top: 20px;
}
